import account from './account';
import personalized from './personalized';
import testParams from './testparams';
import hasdata from './hasdata';
import positionData from './positionData';
import modal from './modalState';
import general from './generalState';
import vehicleInfo from './vehicleId';
import accountId from './accountId';
import designs from './designs';

const root = {
	account,
	personalized,
	testParams,
	hasdata,
	positionData,
	modal,
	general,
	vehicleInfo,
	accountId,
	designs
};

export default root;
