import { a } from '../global-constants';

const initialState = {
	designs: []
};

const designs = (state = initialState, { type, payload }) => {
	switch (type) {
		case a.SET_DESIGNS: {
			return {
				...state,
				designs: payload
			};
		}
		default:
			return state;
	}
};

export default designs;
