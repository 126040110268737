import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';
import { IncentiveModal } from 'wsm-incentive-modal';
import { DetailsModal, AppleWalletButton } from 'wsm-specials-display';
import { setClassNames } from 'ddc-classnames-js';
import { promoPropTypes } from '../utilities/commonPropTypes';
import { g, aR, a } from '../global-constants';
import { setModalState } from '../actions/modalState';
import { shouldShowDetailsButton } from '../utilities/general';
import { getPlacement, isProgramAspectRatioAccount } from '../utilities/aspect';
import { trackEvent } from '../utilities/tracking';

const DetailsButton = ({
	btnStyle,
	btnSize,
	promoIndex,
	customAsset = false,
	classes = [],
	incentive = {},
	applicableVehicle = '',
	promo,
	cta,
	fullLabel = false,
	onShowLeadDialog,
	ctaPrint
}) => {
	const labels = useLabels();
	const { widgetName, windowId, deviceType } = useRequestData();
	const isMobile = deviceType === 'MOBILE';
	const account = useSelector((state) => state.account);
	const personalized = useSelector((state) => state.personalized);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { aspectRatio, enableAppleWallet, enablePrint } = usePrefs();
	const programAspectRatioAccount =
		account && isProgramAspectRatioAccount(account);
	const dispatch = useDispatch();
	const isCoupon =
		aspectRatio === aR.DESKTOP_4X3 || aspectRatio === aR.MOBILE_4X3_COUPON;
	const tabIndexBase = isCoupon ? promoIndex * 10 : promoIndex;
	let applePassBtn = null;
	const isIphone =
		navigator.userAgent.includes('iPhone') &&
		navigator.userAgent.includes('AppleWebKit');

	let button = null;
	let modal = null;

	const isBmwOrMini =
		programAspectRatioAccount === 'bmw' ||
		programAspectRatioAccount === 'mini';

	const disclaimerLabel = !isBmwOrMini
		? labels.get('OFFER_DETAILS_AND_DISCLAIMERS')
		: labels.get('CLICK_HERE_FOR_OFFER_DETAILS');

	const label =
		aspectRatio === aR.MOBILE_6X1 && !fullLabel
			? labels.get('DETAILS')
			: disclaimerLabel;

	const {
		id: promoId,
		type: promoType,
		title: promoTitle,
		incentiveData: { title: incentiveTitle } = {}
	} = promo;

	const showAppleWalletBtn = enableAppleWallet === 'true' && isIphone;
	if (showAppleWalletBtn) {
		applePassBtn = (
			<>
				<AppleWalletButton
					applicableVehicle={applicableVehicle}
					tabIndexBase={promoIndex || 1}
					promoExternal={promo}
				/>
			</>
		);
	}
	const showDetailsBtn = shouldShowDetailsButton(
		promo,
		incentive,
		aspectRatio,
		enableAppleWallet,
		enablePrint
	);

	// Display the button if we have a page fragment to link it to
	// Display the button if we have enablePrint form prefs
	if (showDetailsBtn) {
		// Display cta print button when enablePrint is true
		const ctaPrintBtn = enablePrint === 'true' ? ctaPrint : null;
		const onModalClose = (type) => {
			setIsModalVisible(false);
			if (type !== 'CTA')
				dispatch(setModalState({ type: a.CLOSE_MODAL }));
			if (customAsset) {
				onShowLeadDialog(false);
			}
		};

		if (isModalVisible) {
			modal =
				promoType === g.INCENTIVE ? (
					<IncentiveModal
						account={account}
						incentive={incentive}
						isOpen={isModalVisible}
						isMobile={isMobile}
						onModalClose={onModalClose}
						cta={cta}
						ctaPrint={ctaPrintBtn}
						applePassBtn={applePassBtn}
						isDisclosure={isBmwOrMini}
					/>
				) : (
					<DetailsModal
						isOpen={isModalVisible}
						isMobile={isMobile}
						onModalClose={onModalClose}
						promo={promo}
						cta={cta}
						ctaPrint={ctaPrintBtn}
						applePassBtn={applePassBtn}
						isDisclosure={isBmwOrMini}
					/>
				);
		}

		const onButtonClick = (ev) => {
			ev.preventDefault();
			ev.stopPropagation();
			if (customAsset) {
				onShowLeadDialog(true);
				if (isMobile) {
					window.DDC.mobile.slidein.deactivateOverlay();
				}
			}
			setIsModalVisible(true);
			dispatch(setModalState({ type: a.OPEN_MODAL }));
			trackEvent({
				widgetName,
				windowId,
				event: 'specialsEvent',
				specialType: 'disclaimer click',
				specialCategory:
					promoType.charAt(0) + promoType.slice(1).toLowerCase(),
				specialId: promoId,
				specialPlacementType: getPlacement(aspectRatio),
				specialPersonalized: personalized,
				specialAutoGen: promo.autoGenerated,
				nonInteractive: false,
				promoIndex,
				customAsset,
				title: promoTitle || incentiveTitle
			});
		};

		// data-promo-tracking-label="dialog : inventory query - [days on lot gt 100, body style eq sedan]"
		// Set tabindex='-1' to hide offscreen Button from tabbing for A11Y
		button = (
			<React.Fragment>
				<Button
					bsStyle={
						btnStyle ||
						(aspectRatio === aR.MOBILE_6X1 ? 'primary' : 'link')
					}
					bsSize={btnSize}
					className={setClassNames([
						!customAsset && aspectRatio !== aR.MOBILE_6X1
							? 'text-white'
							: '',
						'py-1',
						programAspectRatioAccount === 'mazda'
							? 'override-line-height'
							: '',
						...classes
					])}
					data-width="670"
					data-title={label}
					tabIndex={tabIndexBase + 7}
					data-promo-tracking-label="dialog"
					data-promo-tracking-action="click"
					data-promo-tracking-description="disclaimer details"
					onClick={(e) => onButtonClick(e)}
					rel="nofollow"
				>
					{label}
					<p className="sr-only">
						{promoType === g.INCENTIVE
							? 'Open Incentive Modal'
							: 'Open Details Modal'}
					</p>
				</Button>
				{modal}
			</React.Fragment>
		);
	}
	return button;
};

DetailsButton.propTypes = {
	btnSize: PropTypes.string,
	btnStyle: PropTypes.string,
	promoIndex: PropTypes.number.isRequired,
	classes: PropTypes.arrayOf(PropTypes.string),
	customAsset: PropTypes.bool,
	incentive: PropTypes.shape({}),
	fullLabel: PropTypes.bool,
	promo: PropTypes.shape(promoPropTypes).isRequired
};

export default DetailsButton;
