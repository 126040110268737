import { addNewRelicPageAction } from 'ddc-new-relic';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestData, usePrefs, useFlags } from 'wsm-common-data';
import { buildPromoRO } from 'wsm-specials-display';
import { trackPerformanceToNR } from '../utilities/tracking';
import { setHasData } from '../actions/hasdata';
import { setPersonalized } from '../actions/personalized';
import { setAccount } from '../actions/account';
import { proxyServiceProvider } from '../utilities/proxyServiceProvider';
import { useURLPrefs } from './useURLPrefs';
import { setDesigns } from '../actions/designs';

const NR_PREFIX = 'PROMOS';
const ENDPOINT_FETCH_ERROR = `${NR_PREFIX}/CLIENT/PROMOS ENDPOINT FETCH ERROR`;

const usePromos = () => {
	const [promosState, setPromosState] = useState({
		promos: [],
		isLoading: true
	});

	const prefs = usePrefs();
	const urlPrefs = useURLPrefs();
	const specialsPrefs = { ...prefs, ...urlPrefs };
	const { locale, deviceType } = useRequestData();
	const flags = useFlags();
	const proxyService = proxyServiceProvider.getInstance(
		flags['ws-specials-client-timeout'],
		flags['ws-specials-client-retries']
	);
	const { vehicleId } = useSelector((state) => state.vehicleInfo);
	const { id: accountId } = useSelector((state) => state.accountId);
	const dispatch = useDispatch();
	const isWindowPresent =
		typeof window !== 'undefined' && Boolean(window.Cookies);
	let pixallId = null;
	if (isWindowPresent) {
		pixallId =
			window.Cookies.get('abc') ?? window.Cookies.get('pxa_id') ?? null;
	}
	const parameters = buildPromoRO(
		specialsPrefs,
		accountId,
		locale,
		pixallId,
		deviceType,
		vehicleId
	);
	const requestUrl = '/api/widget/ws-specials/promos';

	if (typeof window !== 'undefined') {
		// Add NR attribute to check for potential missing cookie
		const pixallCookieIsSet =
			sessionStorage.getItem('pixallCookieIsSet') === 'true';
		const pixallIdMissing = !pixallId && pixallCookieIsSet;
		addNewRelicPageAction('ws-specials-usePromos-fetch', {
			pixallIdMissing
		});
	}

	useEffect(() => {
		proxyService(requestUrl, {
			method: 'POST',
			headers: {
				'content-type': 'application/json;charset=UTF-8'
			},
			body: JSON.stringify({
				parameters
			})
		})
			.then((response) => {
				if (!response) {
					trackPerformanceToNR(
						'ws-specials-promos-fetched-no-response'
					);
					setPromosState({
						promos: [],
						isLoading: false
					});
					return;
				}
				const { promos, personalized, accountInfo, designs } = response;
				dispatch(setPersonalized(personalized));
				dispatch(setHasData(true));
				dispatch(setAccount(accountInfo));
				dispatch(setDesigns(designs));

				setPromosState({
					promos,
					isLoading: false
				});
				trackPerformanceToNR('ws-specials-promos-fetched-success');
			})
			.catch((err) => {
				const errorMsg = err.message;
				setPromosState({
					promos: [],
					isLoading: false
				});
				trackPerformanceToNR('ws-specials-promos-fetched-with-error');
				addNewRelicPageAction('ws-specials-error', {
					parameters,
					errorMsg
				});
				throw new Error(`${ENDPOINT_FETCH_ERROR}: ${err.message}`);
			});
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);
	return [promosState];
};

export { usePromos };
