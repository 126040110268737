import { a } from '../global-constants';

// Action to store account data distributor from specials-sv response
export const setAccount = (payload) => {
	return {
		type: a.SET_ACCOUNT,
		payload
	};
};

// Action to store accountId from requestData
// Get accountId from store to call /promos in usePromos hook
export const setAccountId = (payload) => {
	return {
		type: a.SET_ACCOUNT_ID,
		payload
	};
};
